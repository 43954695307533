import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import pricingTitle from '@assets/images/icon_colored.svg';
import pricingIcon from '@assets/images/price_select.svg';

// 국가별 가격 정보
const priceConfig = {
  KR: { currency: 'KRW', proPrice: 29900, symbol: '₩' },
  US: { currency: 'USD', proPrice: 29.99, symbol: '$' },
  JP: { currency: 'JPY', proPrice: 3300, symbol: '¥' },
  CN: { currency: 'CNY', proPrice: 199, symbol: '¥' },
};

const PricingSection = forwardRef((props, ref) => {
  const { t, i18n } = useTranslation();

  // 현재 언어에 따라 국가 결정 (실제로는 사용자의 위치나 설정에 따라 결정해야 함)
  const getCurrentCountry = () => {
    switch (i18n.language) {
      case 'ko':
        return 'KR';
      case 'en':
        return 'US';
      case 'ja':
        return 'JP';
      case 'zh':
        return 'CN';
      default:
        return 'US';
    }
  };

  const country = getCurrentCountry();
  const { currency, proPrice, symbol } = priceConfig[country];

  const formatPrice = (price) => {
    return new Intl.NumberFormat(i18n.language, { style: 'currency', currency: currency }).format(price);
  };

  return (
    <section id="pricing" ref={ref} className="flex flex-col items-center w-full px-12 py-6 border-b-[1px] border-b-gray-200 bg-white sm:py-12 md-py-15 lg:py-24">
      <div className="flex flex-col w-full max-w-[1200px] gap-6 sm:gap-12 md:gap-15 xl:flex-row">
        <div className="flex items-baseline flex-none gap-4 break-keep">
          <img src={pricingTitle} />
          <div className="relative">
            <h2 className="w-[165px] mb-2 text-3xl font-bold sm:text-4xl">{t('home.pricing.title')}</h2>
            <div className="mb-6 sm:mb-8">
              <div className="inline-flex items-center px-3 py-1 text-sm font-semibold text-indigo-600 bg-indigo-50 rounded-full">
                <span className="mr-1">🎉</span>
                <span>{t('home.pricing.promotion.banner')}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full">
          <h3 className="mb-4 text-xl font-semibold sm:text-2xl sm:mb-6">{t('home.pricing.subtitle')}</h3>
          <p className="mb-6 text-gray-600">{t('home.pricing.description')}</p>
          <div className="grid grid-cols-1 gap-6 price:grid-cols-3 sm:gap-8 sm:grid-cols-2">
            <div className="flex flex-col h-full p-4 text-center rounded-lg outline outline-1 outline-gray-200 sm:p-10 hover:bg-pricing-hover hover:outline-2 hover:outline-custom-personal">
              <div className="flex-grow">
                <div className="border-b-[1px] border-gray-300">
                  <h4 className="mb-3 text-lg font-semibold sm:text-xl sm:mb-4">{t('home.pricing.free.name')}</h4>
                  <p className="mb-5 text-xl font-bold text-indigo-600 sm:text-2xl xl:text-3xl">{t('home.pricing.free.price')}</p>
                </div>
                <ul className="mt-4 space-y-2 text-sm text-left text-gray-600">
                  <li>· {t('home.pricing.free.features.guides', { count: 3 })}</li>
                  <li>· {t('home.pricing.free.features.points', { count: 10 })}</li>
                  <li>· {t('home.pricing.free.features.public')}</li>
                  <li>· {t('home.pricing.free.features.basicAnalytics')}</li>
                </ul>
              </div>
              <button className="flex justify-between w-full px-5 py-4 mt-6 text-white transition duration-300 bg-indigo-600 rounded-full hover:bg-indigo-700">
                {t('home.pricing.startButton.free')}
                <img src={pricingIcon} />
              </button>
            </div>
            <div className="flex flex-col h-full p-4 text-center rounded-lg outline outline-1 outline-gray-200 hover:bg-pricing-hover hover:outline-2 hover:outline-custom-personal sm:p-10">
              <div className="flex-grow">
                <div className="relative border-b-[1px] border-gray-300">
                  <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                    <div className="inline-flex items-center px-3 py-1 text-sm font-semibold text-indigo-600 bg-indigo-50 rounded-full whitespace-nowrap">
                      <span className="mr-1">🎉</span>
                      <span>{t('home.pricing.promotion.badge')}</span>
                    </div>
                  </div>
                  <h4 className="mb-3 text-lg font-semibold sm:text-xl sm:mb-4">{t('home.pricing.pro.name')}</h4>
                  <div className="flex flex-col items-center mb-5">
                    <div className="flex items-center gap-2">
                      <p className="text-xl font-bold text-indigo-600 sm:text-2xl xl:text-3xl">
                        {formatPrice(proPrice)}
                      </p>
                      <span className="text-lg text-gray-600">{t('home.pricing.pro.period')}</span>
                    </div>
                    <div className="mt-2 text-sm text-indigo-600">
                      <span>{t('home.pricing.promotion.description')}</span>
                    </div>
                  </div>
                </div>
                <ul className="mt-4 space-y-2 text-sm text-left text-gray-600">
                  <li>· {t('home.pricing.pro.features.guides', { count: 10 })}</li>
                  <li>· {t('home.pricing.pro.features.points', { count: 30 })}</li>
                  <li>· {t('home.pricing.pro.features.private')}</li>
                  <li>· {t('home.pricing.pro.features.advancedAnalytics')}</li>
                  <li>· {t('home.pricing.pro.features.support')}</li>
                </ul>
              </div>
              <button className="flex justify-between w-full px-5 py-4 mt-6 text-white transition duration-300 bg-indigo-600 rounded-full hover:bg-indigo-700">
                {t('home.pricing.startButton.pro')}
                <img src={pricingIcon} />
              </button>
            </div>
            <div className="flex flex-col h-full p-4 text-center rounded-lg outline outline-1 outline-gray-200 hover:bg-pricing-hover hover:outline-2 hover:outline-custom-personal sm:p-10">
              <div className="flex-grow">
                <div className="border-b-[1px] border-gray-300">
                  <h4 className="mb-3 text-lg font-semibold sm:text-xl sm:mb-4">{t('home.pricing.enterprise.name')}</h4>
                  <p className="mb-5 text-xl font-bold text-indigo-600 sm:text-2xl xl:text-3xl">{t('home.pricing.enterprise.price')}</p>
                </div>
                <ul className="mt-4 space-y-2 text-sm text-left text-gray-600">
                  <li>· {t('home.pricing.enterprise.features.customGuides')}</li>
                  <li>· {t('home.pricing.enterprise.features.customPoints')}</li>
                  <li>· {t('home.pricing.enterprise.features.customAnalytics')}</li>
                  <li>· {t('home.pricing.enterprise.features.dedicatedSupport')}</li>
                  <li>· {t('home.pricing.enterprise.features.customIntegration')}</li>
                </ul>
              </div>
              <a href="mailto:enterprise@taphere.com?subject=Enterprise%20Plan%20Inquiry" className="flex justify-between w-full px-5 py-4 mt-6 text-white transition duration-300 bg-indigo-600 rounded-full hover:bg-indigo-700">
                {t('home.pricing.startButton.enterprise')}
                <img src={pricingIcon} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default PricingSection;
