import React from 'react';
import { Controller } from 'react-hook-form';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from '@ckeditor/ckeditor5-build-classic';
import config from '@config';
import { useEditorImageUploadMutation } from '../../services/fileService';

export default function FormCKEditor({ control, data, placeholder, name }) {
  const [uploadEditorImage] = useEditorImageUploadMutation();
  const contentChangeHandler = (editor, onChange) => {
    onChange(editor.getData());
  };

  const customUploadAdapter = (loader) => {
    return {
      upload() {
        return new Promise((resolve, reject) => {
          const data = new FormData();
          loader.file.then(async (file) => {
            data.append('name', file.name);
            data.append('file', file);

            const res = await uploadEditorImage(data);

            console.log('res', res);

            resolve({
              default: `${config.API_SERVER_HOST}/files/editor/${res.data.filename}`,
            });
          });
        });
      },
    };
  };

  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return customUploadAdapter(loader);
    };
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={data || ''}
      render={({ field: { onChange, value } }) => (
        <div>
          <CKEditor
            language="ko"
            editor={Editor}
            config={{
              extraPlugins: [uploadPlugin],
              placeholder: placeholder,
            }}
            data={value}
            onChange={(event, editor) => contentChangeHandler(editor, onChange)} // onChange로 CKEditor 값 처리
            onBeforeLoad={(CKEDITOR) => (CKEDITOR.disableAutoInline = true)}
          />
        </div>
      )}
    />
  );
}
