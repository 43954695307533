import { apiService } from './api';

export const flieApi = apiService.injectEndpoints({
  endpoints: (builder) => ({
    editorImageUpload: builder.mutation({
      query: (data) => ({
        url: '/files/editor/image',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useEditorImageUploadMutation } = flieApi;
